@import '../../../styles/main';
.label-suffix {
  font-weight: $font-medium;
}
.sel-container {
  width: 100%;
  max-width: 450px;
  margin: 0 0 1rem 0;
}

.selection-modal {
  commonux-select .options-slot {
    position: fixed;
    top: auto;
  }
}

.select-radio-option {
  display: flex;
  justify-content: space-between;
  margin-right: $xxl;
  align-items: center;
}

.form-header {
  color: $color-text-black-primary;
  font-size: $font-size-s;
  font-weight: $font-bold;
  line-height: $line-height-m;
  margin-bottom: 0.5rem;
}

.form-group-header {
  color: $color-text-black-secondary;
  font-weight: $font-bold;
  font-size: $font-size-m;
  margin-bottom: 1rem;
}

.option-card {
  #content {
    font-size: $font-size-s;
    color: $color-text-black-secondary;
    line-height: $line-height-m;
  }

  .option-card-header {
    font-weight: $font-bold;
    font-size: $font-size-m;
    line-height: $ml;
  }
}

.modal-footer {
  @include flex-align-center-vertical;
  width: 100%;
  justify-content: space-between;

  .modal-action-buttons {
    display: flex;
    margin-left: auto;
    flex-direction: row;
  }
}

.card-container {
  @include grid-layout-with-breakpoints;
  grid-gap: $ml;

  .option-card {
    .card-container {
      display: grid;
      grid-template-columns: auto;
      grid-auto-rows: auto;
      padding: $ml;
      margin: 0;
      height: 100%;
      gap: $s;

      @include media-mobile() {
        padding: $ml;
      }
      .card-header {
        display: flex;
        align-items: center;
        gap: $s;
        img {
          width: $ml;
          height: $ml;
        }
      }
    }
    .disabled {
      background-color: $color-white-primary !important;
      opacity: 0;
    }
  }
}

.support-card-container {
  align-items: flex-start;
  margin-top: 80px;

  @include media-mobile() {
    margin-top: $xl;
  }

  .option-card {
    margin-right: 1rem;

    #content {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .card-container {
      background-color: black;
      color: white;
      flex-direction: row;
      width: auto;
      align-items: center;
      padding: $xxl;

      @include media-mobile() {
        flex-direction: column;
        align-items: start;
        gap: 0;
        padding: $ml;
      }

      .option-card-image {
        color: white;
      }
    }

    .support-card-header {
      font-size: $ml;
      font-weight: $font-bold;
      line-height: 36px;

      @include media-mobile() {
        font-weight: $font-medium;
      }
    }

    .support-card-desc {
      margin-top: $xs;
      width: 100%;
    }

    .support-image-container {
      align-self: center;

      @include media-mobile() {
        display: none;
      }

      .support-arrow {
        fill: white;
        align-self: center;
      }
    }
  }
}

.footer-step {
  font-size: $font-size-s;
  color: $color-text-black-secondary;
}

.page-header-container {
  margin-top: 120px;
  margin-bottom: 80px;

  @include media-mobile() {
    margin-top: 0;
    margin-bottom: 60px;
  }

  @include media-tablet() {
    margin-top: $s;
    margin-bottom: 56px;
  }
}

.projects-header-container {
  margin-top: 80px;
  margin-bottom: $xl;

  @include media-mobile() {
    margin-top: 0;
    margin-bottom: $xl;
  }

  @include media-tablet() {
    margin-top: $s;
    margin-bottom: 56px;
  }
}

.block-header {
  font-size: $font-size-l;
  font-weight: $font-medium;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: $color-text-black-primary;
  @include media-mobile() {
    font-size: $font-size-s;
    line-height: $line-height-m; /* 142.857% */
  }
}

.projects-header {
  color: $color-brand-black;
  font-size: $xl;
  font-style: normal;
  font-weight: $font-regular;
  line-height: $xxl;
}

.project-card {
  @include media-mobile() {
    width: 50%;
  }
}

.projects-search {
  width: 50%;
  padding-bottom: $xl;

  @include media-mobile() {
    width: 100%;
  }
}

.home-button {
  color: var(--foreground-secondary, var(--foreground-secondary, #696969));
  fill: var(
    --foreground-secondary,
    var(--foreground-secondary, #696969)
  ) !important;
  font-size: $m;
  font-style: normal;
  font-weight: $font-regular;
  line-height: $ml;
  margin: $s 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.home-button-icon {
  fill: var(
    --foreground-secondary,
    var(--foreground-secondary, #696969)
  ) !important;
  padding-right: $s;
}

.all-projects-container {
  display: flex;
  flex-direction: row;
  gap: $m;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;

  @include media-mobile() {
    justify-content: center;
  }

  commonux-card > .card-container.default {
    margin-top: 0;
    background-image: url('brand-pattern.jpg');
    background-size: cover;

    .project-name {
      line-clamp: 2;
      -webkit-line-clamp: 2;
    }
  }

  .project-card {
    @include media-mobile() {
      width: 100%;
      min-width: 100%;
      height: 160px;
      min-height: 160px;
    }
  }
}

.projects-search-container {
  display: flex;
  justify-content: space-between;

  @include media-mobile() {
    flex-direction: column;
    padding-bottom: $xl;
  }

  commonux-tooltip > .tooltip-container.top {
    left: -20px;
  }
}

.project-results-label {
  color: var(--text-black-secondary, var(--foreground-secondary, #696969));
  font-size: $m;
  font-style: normal;
  font-weight: $font-medium;
  line-height: 150%;
}

.block-desc {
  font-size: $font-size-m;
  font-weight: $font-regular;
  line-height: $line-height-xl;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: $ml;
  @include media-mobile() {
    font-size: $font-size-s;
    line-height: $line-height-m;
    font-weight: $font-regular;
  }
}

.login-button {
  margin-left: $m;
}

.bannerImage {
  width: 100%;
}

.login-model {
  .dialog {
    width: -moz-fit-content !important;
    width: fit-content !important;

    @include media-mobile() {
      width: -moz-fit-content;
      width: fit-content;
    }
  }
}

.modal-container {
  width: 343px;

  @include media-mobile() {
    width: -moz-fit-content;
    width: fit-content;
  }
}

.info-modal-container {
  width: 480px;
  margin: 0 !important;

  @include media-mobile() {
    width: -moz-fit-content;
    width: fit-content;
  }
}

.modal-content {
  display: block;
  margin: $ml 0 0;
  margin-top: $l;
  font-size: $font-size-s;
  font-weight: $font-regular;

  .input-style {
    width: 100%;
  }
}

.modal-button-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: $ml 0;
}

.info-modal-button-container {
  margin-top: $l;
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
}

.cancel-button-container {
  margin-top: $s;
}

.open-button {
  width: 100%;
  height: $xl;
}

.info-button {
  width: 100%;
  height: $xl;
  margin-left: $s;
}

.cancel-button {
  width: 100%;
  height: $xl;
}

.info-cancel-button {
  width: 100%;
  height: $xl;
}

.support-text {
  color: black;
}

.support-card {
  margin-left: 1rem;
}

.reading-container {
  .reading-label {
    font-size: $ml;
    font-weight: $font-medium;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .reading-contents {
    @include grid-layout-with-breakpoints;
    grid-auto-rows: auto;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: $s;
  }

  .option-card {
    width: 100%;
    @include media-mobile() {
      margin-right: 0;
    }

    .content-image-container {
      width: 100%;
      justify-self: center;
      margin-top: 0;
    }

    .content-image {
      width: 100%;
      height: 132px;
      border-radius: $s;

      @include media-mobile() {
        height: 175px;
      }

      @include media-tablet() {
        height: 175px;
      }
    }

    .card-container {
      height: 100%;
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: left;
      color: var(--color-grey-90);
    }

    .support-card-type {
      font-size: $font-size-s;
      font-weight: $font-regular;
      line-height: $m;
      letter-spacing: 0em;
      color: $color-text-black-primary;
    }

    .support-card-updated {
      margin-top: $xs;
      font-size: $sm;
      font-weight: $font-regular;
      line-height: $font-size-s;
      letter-spacing: 0em;
      color: $color-text-black-secondary;
    }

    .support-card-header {
      display: -webkit-box;
      font-size: $font-size-ml;
      font-weight: $font-medium;
      line-height: $ml;
      letter-spacing: 0;
      color: $color-text-black-primary;
      margin-top: $xs;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .support-card-desc {
      display: -webkit-box;
      color: $color-text-black-primary;
      margin-top: $m;
      font-size: $font-size-s;
      font-weight: $font-regular;
      line-height: $font-size-ml;
      line-clamp: 4;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.documents-input-container {
  margin-top: $xxl;
}

.documents-button-container {
  margin-top: $ml;
}

.documents-title {
  font-size: $font-size-s;
  font-weight: $font-medium;
  margin-bottom: $s;
  margin-top: 0;
}

.documents-input {
  width: 600px;

  @include media-mobile() {
    width: auto;
  }
}

.select-description {
  color: $color-text-black-secondary;
  font-size: $font-size-s;
  line-height: $line-height-m;
  margin-top: $s;
}

.create-project-modal-content {
  margin: 1.5rem 0;
  margin-top: $ml;
  display: block;

  .input-style {
    width: 100%;
  }
}

.create-project-cancel-button {
  margin-right: $m;
}

.options-container {
  height: 180px;
  overflow: scroll;
}

.recent-header {
  color: $color-brand-black;
  font-size: $font-size-l;
  font-weight: $font-medium;
  line-height: $line-height-xxl;
  margin-top: $ml;
}

.page-projects-container {
  display: flex;
  flex-direction: column;

  commonux-tooltip > .tooltip-container.right {
    top: -8px;
  }
}

.projects-container {
  display: flex;
  gap: 16px;
  overflow-x: auto;
  height: max-content;
  flex-direction: column;

  .project-card {
    background-image: url('brand-pattern.jpg');
    background-size: cover;
    position: relative;
    border-radius: $s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $m;
    cursor: pointer;
    .project-name {
      line-clamp: 2;
      -webkit-line-clamp: 2;
    }
    &:hover {
      box-shadow:
        0px 0px 1px 0px rgba(0, 0, 0, 0.08),
        0px 2px 4px 0px rgba(0, 0, 0, 0.08),
        0px 8px 16px 0px rgba(0, 0, 0, 0.12);
    }
  }
}
.projects-list-container {
  display: flex;
  gap: $ml;
  align-items: flex-start;
  padding-bottom: $ml;
}

.project-card {
  width: 237px;
  height: 200px;
  min-width: 220px;
  min-height: 200px;

  .card-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column !important;
    gap: $s !important;
    align-self: stretch;
  }

  .project-menu {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  &:hover {
    .card-container {
      justify-content: space-between;
    }
    .kebab-icon-container {
      visibility: visible;
    }
    .project-menu {
      visibility: visible;
    }
  }
}

.project-modified-label {
  color: $color-text-black-secondary;
  font-size: $sm;
  font-weight: $font-regular;
  line-height: $font-size-s;
  margin: $s 0;
}

.project-name {
  color: $color-text-black-primary;
  font-size: $font-size-ml;
  font-weight: $font-medium;
  line-height: $ml;
  display: -webkit-box;
  line-clamp: 4;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.view-all-projects {
  color: $color-brand-white;
  text-align: center;
  font-size: $font-size-ml;
  font-weight: $font-medium;
  line-height: $ml;
}

.all-projects-card {
  width: 220px;
  height: 200px;
  min-width: 220px;
  min-height: 200px;

  .card-container {
    background-color: black !important;
    display: flex;
    width: 100% !important;
    height: 100% !important;
    justify-content: center;
    gap: 0 !important;
  }
}

.project-tooltip-container {
  display: flex;
  flex-direction: column;
}

.project-tooltip-header {
  color: $color-grey-90;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 115%; /* 16.1px */
  margin-bottom: 4px;
}

.project-tooltip-label {
  color: $color-grey-90;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 115%; /* 13.8px */
}

commonux-button-group-item > .light.primary-black.active {
  background-color: $color-grey-40;
}

.project-view-as-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.project-listview-table {
  width: 100%;
  tr {
    height: 56px;
    background-color: #ffffff;
    font-weight: $font-regular;
    font-size: $font-size-s;
    border-bottom: 1px solid var(--grey-30, #ebebeb);
  }
  tr:first-child {
    color: var(--text-black-secondary, #696969);
    background-color: #f5f5f5;
    font-weight: $font-medium;
    border-bottom: 1px solid var(--brand-colors-black, #000);
  }
  td {
    padding-left: $m;
  }
  td:first-child {
    padding-right: $xl;
  }
  td:nth-child(5) {
    border-right: 0;
  }
  .table-listview-project-name {
    cursor: pointer;
  }
}

.click-handler-container {
  line-height: 0;
  font-size: 0;
}

.projects-narrow-menu {
  position: relative;
  display: inline-block;
  height: auto;
  cursor: pointer;
  line-height: $m;
  font-size: $font-size-s;

  .active {
    & > button {
      &.discreet {
        background-color: $color-black-opacity-6 !important;
      }
    }
  }

  .menu-container {
    position: relative;
    display: none;
    z-index: 999;

    .content {
      position: absolute;
      overflow: hidden;
      list-style: none;
      padding: $s 0;
      background-color: $color-white-primary;
      right: -$xs;
      min-width: 163px;
      border-radius: $border-radius-xs;
      margin: 0;
      box-shadow: 0 $xs $sm rgba(0, 0, 0, 0.25);

      .menu-item {
        box-sizing: border-box;
        padding-left: $m;
        padding-right: $sm;
        width: 230px;

        @include flex-align-center-vertical();

        &.large {
          height: $xl;
          padding-top: 10px;
          padding-bottom: 10px;
        }

        &.medium {
          height: $l;
          padding-top: $s;
          padding-bottom: $s;
        }

        &:hover {
          background-color: $color-grey-10;
          width: 230px;
        }
      }

      .menu-disabled {
        color: #1f1f1f66;
        cursor: default;
        padding-top: $s;
        padding-bottom: $s;

        &:hover {
          background-color: unset;
          width: 230px;
        }
      }
    }
  }

  .show {
    display: block;
  }
}

.kebab-icon-container {
  visibility: hidden;
  display: flex;
  padding: $s;
  background-color: inherit;
  &.active {
    background-color: $color-grey-10;
    border-radius: $m;
    visibility: visible;
  }
  &:hover {
    background-color: $color-grey-10;
    border-radius: $m;
  }
}
.recent-project-menu-container {
  width: 100%;
}

.project-menu-container {
  display: flex;
  flex-direction: column;
  gap: 0;
  width: 176px;
  background-color: $color-brand-white;
  position: absolute;
  box-shadow: 0 $s $sm 0 rgba(0, 0, 0, 0.25);
  right: $m;
  border-radius: $s;
  top: 52px;
  padding: $xs;
  button {
    display: flex;
    gap: $s;
    align-items: center;
    padding: $s $sm $s $m;
    background-color: inherit;
    label {
      cursor: pointer;
    }
    &:hover {
      background-color: $color-grey-10;
    }
  }
  &.active {
    display: block;
  }
}

.list-view {
  position: relative;
  .list-project-menu-container {
    @extend .project-menu-container;
    right: 72px;
    top: $l;
    @include media-mobile() {
      right: $ml;
    }
    @include media-tablet() {
      right: $xl;
    }
  }
}

.project-page-grid-container {
  @include media-mobile() {
    width: 100%;
    margin: 0;
    padding-bottom: 0;
  }
  @include media-tablet() {
    padding-bottom: 0;
    margin: 0;
  }
  &:hover {
    box-shadow:
      0px 0px 1px 0px rgba(0, 0, 0, 0.08),
      0px 2px 4px 0px rgba(0, 0, 0, 0.08),
      0px 8px 16px 0px rgba(0, 0, 0, 0.12);
  }
}

.homepage-things-to-know-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $l;
  @include media-mobile() {
    gap: $ml;
  }
  @include media-tablet() {
    gap: $ml;
  }
  h4 {
    font-size: $font-size-l;
    font-weight: $font-medium;
    line-height: $line-height-xxl;
  }
  .selected {
    div {
      color: $color-grey-90;
      font-size: $font-size-m;
      font-weight: $font-medium;
      line-height: $line-height-xl;
    }
  }
  commonux-tab-group {
    display: flex;
    flex-direction: column;
    gap: $ml;
    @include media-mobile() {
      padding: 0;
    }
    @include media-tablet() {
      padding: 0;
    }
  }
}

.selection-modal {
  .dialog {
    .content {
      @include custom-scrollbar();
    }
  }
}

.homepage-header-container {
  display: flex;
  align-items: center;
  gap: $ml;
  p {
    margin: 0;
  }
}

.homepage-common-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $s;
  width: 781px;
  section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: $s;
    .brand-plate {
      padding: 0 !important;
      .productname {
        font-size: $font-size-xxl !important;
        font-weight: $font-medium !important;
        line-height: normal !important;
        @include media-mobile() {
          font-size: $font-size-xl;
        }
      }
      .onlyproductname::before {
        font-size: $font-size-xl !important;
      }
    }
  }
  @include media-mobile() {
    width: 100%;
    padding-right: $ml;
  }
}

.homepage-dashboard-text {
  font-size: $font-size-xl;
  font-style: normal;
  font-weight: $font-light;
  line-height: 56px;
  @include media-mobile() {
    font-size: $font-size-l;
    line-height: $line-height-xxl;
  }
  @include media-tablet() {
    font-size: $font-size-l;
    line-height: $line-height-xxl;
  }
}

.homepage-dashboard-description {
  color: $color-grey-90;
  font-size: $font-size-m;
  font-weight: $font-regular;
  line-height: $line-height-xl; /* 24px */

  @include media-mobile() {
    font-size: $font-size-s;
    line-height: $line-height-m;
  }
}

.homepage-info-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $ml;
  color: $color-grey-90;
  font-size: $font-size-m;
  font-style: normal;
  font-weight: 300;
  line-height: $line-height-s;
  p {
    font-size: $font-size-m;
    font-weight: 700;
    @include media-mobile() {
      font-size: $font-size-s;
      font-weight: $font-medium;
      line-height: $line-height-m;
    }
  }
  @include media-mobile() {
    width: 100%;
    overflow-x: auto;
  }
}

.recent-project-desc-block {
  display: flex;
  gap: $s;
  flex-direction: column;
}

.homepage-header-container-unauthorize {
  gap: $xl;
}
.delete-project-modal {
  .dialog {
    display: flex;
    padding: $ml !important;
    flex-direction: column;
    width: 536px;
    border-radius: $s !important;
    .content {
      padding-left: 0 !important;
      padding-right: 0 !important;
      box-shadow: none !important;
    }
    .header {
      padding: 0 !important;
    }
  }
}
.delete-modal-container {
  display: flex;
  flex-direction: column;
  gap: $ml;
  p {
    margin-top: $s;
    margin-bottom: 0;
    padding: 0 $l !important;
    font-size: $font-size-s;
    font-weight: $font-regular;
    line-height: $line-height-s;
  }
}
.delete-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: $m;
}

.drive-motor-option-container {
  border: 1px solid $color-grey-20;
  border-radius: $s;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $s;
  margin-right: $s;
  padding: $l;
  gap: $m;
  &:hover {
    background-color: $color-grey-10;
    cursor: pointer;
  }
}

.plc-hmi-option-container {
  border: 1px solid $color-grey-20;
  border-radius: $s;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $s;
  margin-left: $s;
  padding: $l;
  gap: $m;
  &:hover {
    background-color: $color-grey-10;
    cursor: pointer;
  }
}

.project-type-text {
  color: $color-grey-90;
  font-size: $font-size-s;
  font-weight: $font-regular;
}

.project-type-option-container {
  display: flex;
  justify-content: space-between;

  .disabled {
    opacity: 0.4;
    cursor: not-allowed !important;
    pointer-events: none;
  }
}

.option-title {
  color: $color-brand-black;
  font-weight: $font-bold-700;
  font-size: $font-size-m;
  inline-size: 120px;
  text-align: center;
}

.project-type-selection-modal {
  font-size: $font-size-ml;
  font-weight: $font-medium;

  .content {
    overflow: visible !important;
  }
}

.desc-textarea-container {
  commonux-text-area .small textarea {
    resize: none !important;
  }
  .label-suffix {
    font-weight: $font-medium;
  }
}

.recent-project-header {
  display: flex;
}

.project-type-container {
  display: flex;
  align-items: center;
  min-width: fit-content;
}
.project-type-title {
  color: $color-brand-black;
  font-weight: $font-regular;
  font-size: $font-size-xs;
  margin-left: $xs;
  text-align: center;
}
.projectType-value-container {
  display: flex;
}
.projectType-value-title {
  margin-left: $s;
}
.homepage-header-content {
  @include flex-align-center-vertical();
  align-items: flex-start;
  flex-direction: column;
  gap: $xl;
}
